import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { useRouter } from 'apis/history';
import { almostWhite } from 'styles/colors';
import { mobile } from 'styles/breakpoints';
import { updateQuizAnswers } from 'state/user/effects';
import {
  BuiltForYou,
  Featured,
  FlexWrapper,
  H1,
  Image,
  Seo,
  Testimonials,
} from 'components';

import { Analytics } from 'apis/Analytics';
import loadable from '@loadable/component';

export const GetTreatmentOnline = loadable(
  () => import('./sections/GetTreatmentOnline'),
  {
    ssr: true,
  },
);
export const Hero = loadable(() => import('./sections/Hero'), { ssr: true });
export const SymptomFree = loadable(() => import('./sections/SymptomFree'), {
  ssr: true,
});
export const Benefits = loadable(() => import('./sections/Benefits'), {
  ssr: true,
});
export const LivingWithHeartIssues = loadable(
  () => import('./sections/LivingWithHeartIssues'),
  {
    ssr: true,
  },
);

const Landing: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const { goToQuiz } = useRouter();
  const handleGenderSelection = React.useCallback(
    (gender: string) => {
      dispatch(updateQuizAnswers({ gender }));
      goToQuiz(`?gender=${gender}`);
    },
    [dispatch],
  );

  const handleCtaClick = React.useCallback(() => {
    Analytics.trackCTAButton('landing');
    goToQuiz();
  }, [goToQuiz]);

  if (typeof window === 'undefined') return null;

  return (
    <>
      <Seo
        title="Personalized support for cardiovascular system | Pulsio"
        description="Pulsio is fully personalized support and treatment plan to control your cholesterol, lower blood pressure, balance sugar levels and manage unhealthy weight."
      />
      <Hero onSelect={handleGenderSelection} />
      <StyledBuiltForYou />
      <Featured onClick={goToQuiz} />
      <GetTreatmentOnline />
      <SymptomFree />
      <Benefits />
      <LivingWithHeartIssues />
      <Testimonials
        onCtaClick={handleCtaClick}
        title={
          <FlexWrapper
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
          >
            <H1 color={almostWhite}>Does it</H1>
            <Image
              src="does_it_work_woman"
              width="4.375rem"
              height="auto"
              margin="0 1.25rem"
            />
            <H1 color={almostWhite}>work?</H1>
          </FlexWrapper>
        }
        description="Fireflux\'s reviews and results."
      />
    </>
  );
});

Landing.displayName = 'Landing';

export default Landing;

const StyledBuiltForYou = styled(BuiltForYou)`
  padding-bottom: 5.75rem;

  @media ${mobile} {
    padding: 0 0 3.625rem;
  }
`;
